import React, { useState } from "react";
import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import Sidebar from "../components/Sidebar";

export const Index = (props) => {
  const [sidebarClosingStatus, setSidebarClosingStatus] = useState(true);
  return (
    <div className="flex">
      <div
        id="sidebar"
        className="invisible sm:visible transition-all duration-150 ease-in-out"
      >
        <Sidebar
          setSidebarClosingStatus={setSidebarClosingStatus}
          sidebarClosingStatus={sidebarClosingStatus}
        />
      </div>
      <div
        className={`w-full  ${
          sidebarClosingStatus ? `sm:ml-[240px]` : `sm:ml-[80px]`
        }`}
      >
        {/* {currentRoute.includes('dashboard') && <Dashboard setSidebarClosingStatus={setSidebarClosingStatus} />} */}
        <Dashboard setSidebarClosingStatus={setSidebarClosingStatus} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
