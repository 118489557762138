import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.png";
import favicon from "../assets/images/favicon.png";
import Avatar from "../assets/images/Avatar.png";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useSelector, useDispatch } from "react-redux";
// import 'tippy.js/dist/svg-arrow.css';

// icons
import { LiaPencilRulerSolid } from "react-icons/lia";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineSupport } from "react-icons/hi";
import { IoMdSearch } from "react-icons/io";
import { MdInsertChartOutlined } from "react-icons/md";
import { GiMedicines } from "react-icons/gi";

export const Sidebar = (props) => {
  const { sidebarClosingStatus, setSidebarClosingStatus } = props;
  // get current route
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentRoute = location.pathname.slice(1);

  const [selectedLink, setSelectedLink] = useState("dashboard");
  const [logoHoverStatus, setLogoHoverStatus] = useState(false);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch({
      type: "SET_USER",
      payload: {
        email: user?.email,
        name: user?.name,
      },
    });
  }, []);
  useEffect(() => {
    setSelectedLink(currentRoute);
  }, [currentRoute]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/signin");
  };

  const UpperData = [
    {
      title: "Dashboard",
      redirectLink: `dashboard`,
      selectedSvgCode: (
        <MdInsertChartOutlined className={`w-5 h-5 text-primary`} />
      ),
      unSelectedSvgCode: <MdInsertChartOutlined className="w-5 h-5 " />,
    },
  ];

  const LowerData = [
    {
      title: "Support",
      redirectLink: "dashboard/support",
      selectedSvgCode: <HiOutlineSupport className={`w-5 h-5 text-primary`} />,
      unSelectedSvgCode: <HiOutlineSupport className="w-5 h-5" />,
    },
    {
      title: "Settings",
      redirectLink: "dashboard/settings",
      selectedSvgCode: <AiOutlineSetting className={`w-5 h-5 text-primary`} />,
      unSelectedSvgCode: <AiOutlineSetting className="w-5 h-5" />,
    },
  ];

  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div
      id="sidebar"
      className={`w-full ${
        sidebarClosingStatus ? `max-w-[240px]` : `max-w-[80px]`
      } h-screen z-10 fixed bg-white border-gray-200 border-r-[1.15px] ease-in-out duration-300`}
    >
      <div className=" h-full relative">
        <div
          className="flex justify-between items-center px-4 mt-4 py-1 cursor-pointer"
          onMouseEnter={() => {
            if (!sidebarClosingStatus) {
              setLogoHoverStatus(true);
            }
          }}
          onMouseLeave={() => {
            if (!sidebarClosingStatus) {
              setLogoHoverStatus(false);
            }
          }}
        >
          <div
            className={`flex w-full items-center ${
              !sidebarClosingStatus ? "justify-center" : null
            }`}
          >
            {
              sidebarClosingStatus ? (
                // <img src={logo} alt='logo' className='w-14 ml-1 py-1' />
                <img src={logo} className="w-1/2 h-auto" />
              ) : (
                /* <img
                  src={logo}
                  className={`w-auto h-auto ${logoHoverStatus && "hidden"}`}
                /> */
                <img
                  src={logo}
                  className={`w-auto h-auto ${
                    logoHoverStatus ? "hidden" : "pb-3"
                  } pt-2`}
                />
              )
              // <img src={favicon} alt='logo' className={`w-7 mx-auto ml-2.5 ${logoHoverStatus && `hidden`}`} />
            }
          </div>
          <div
            onClick={() => setSidebarClosingStatus(!sidebarClosingStatus)}
            className={`${sidebarClosingStatus ? "p-1" : "p-0"}`}
          >
            {(sidebarClosingStatus || logoHoverStatus) && (
              <svg
                className={`cursor-pointer ease-in duration-100 ${
                  !sidebarClosingStatus && `rotate-180 `
                } ${!sidebarClosingStatus && logoHoverStatus && `mr-4 h-7`}`}
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={(e) => {
                  if (window.innerWidth < 640) {
                    document
                      .getElementById("sidebar")
                      .classList.toggle("invisible");
                  }
                }}
              >
                <path
                  d="M13 11L8 6L13 1M6 11L1 6L6 1"
                  stroke="#101828"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
        <hr className="bg-gray-200 h-[1.15px] mt-3" />

        <ul className="mt-4 px-3 space-y-2.5">
          {UpperData.map((item, index) => (
            <li
              key={index}
              className={`flex items-center py-1.5 rounded-lg cursor-pointer transition-all duration-200 ease-in gap-x-3 ${
                sidebarClosingStatus ? `pl-3 pr-1` : `px-3 ml-1 justify-center`
              } ${
                selectedLink === item.redirectLink
                  ? ` bg-primary-light`
                  : `text-[#344054]`
              }`}
              onClick={() => {
                navigate(`/${item.redirectLink}`);
                document
                  .getElementById("sidebar")
                  .classList.toggle("invisible");
              }}
            >
              {sidebarClosingStatus ? (
                selectedLink === item.redirectLink ? (
                  item.selectedSvgCode
                ) : (
                  item.unSelectedSvgCode
                )
              ) : (
                <Tippy theme="custom" placement="right" content={item.title}>
                  {selectedLink === item.redirectLink
                    ? item.selectedSvgCode
                    : item.unSelectedSvgCode}
                </Tippy>
              )}
              {sidebarClosingStatus && (
                <p
                  className={`text-md font-Inter font-[500] ease-in duration-300 rounded-lg ${
                    selectedLink === item.redirectLink
                      ? `text-primary`
                      : `text-[#344054]`
                  } `}
                >
                  {item.title}
                </p>
              )}
            </li>
          ))}
        </ul>

        <div className="absolute bottom-8 w-full">
          <ul className="mt-4 px-3 space-y-2.5">
            {LowerData.map((item, index) => (
              <li
                key={index}
                className={`flex items-center gap-x-3 py-1.5 rounded-lg cursor-pointer transition-all duration-200 ease-in ${
                  sidebarClosingStatus ? `px-5` : `px-3 ml-1 justify-center`
                } ${
                  selectedLink.includes(item.redirectLink)
                    ? `bg-primary-light`
                    : `text-[#344054]`
                }`}
              >
                {sidebarClosingStatus ? (
                  selectedLink.includes(item.redirectLink) ? (
                    item.selectedSvgCode
                  ) : (
                    item.unSelectedSvgCode
                  )
                ) : (
                  <Tippy theme="custom" placement="right" content={item.title}>
                    {selectedLink.includes(item.redirectLink)
                      ? item.selectedSvgCode
                      : item.unSelectedSvgCode}
                  </Tippy>
                )}
                {sidebarClosingStatus && (
                  <p
                    className={`text-[17px] font-Inter font-[500] ease-in duration-300 rounded-lg ${
                      selectedLink.includes(item.redirectLink)
                        ? `text-primary`
                        : `text-[#344054]`
                    } `}
                  >
                    {item.title}
                  </p>
                )}{" "}
              </li>
            ))}
          </ul>

          <hr className="bg-gray-200 h-[1.15px] mt-1" />

          <div className="flex justify-between item-center px-3 mt-3">
            <div className="flex items-center gap-x-3 px-2 truncate mr-3">
              {true ? (
                <img
                  className="h-7 ml-2"
                  onClick={() => {
                    navigate(`/your-dex`);
                  }}
                  src={Avatar}
                />
              ) : (
                <div className="animate-pulse space-y-2">
                  <div className="h-9 w-9 bg-slate-400 rounded-full"></div>
                </div>
              )}

              {sidebarClosingStatus &&
                (true ? (
                  <div className="">
                    <p className="leading-5">{user?.name}</p>
                    <p className="truncate leading-5">{user?.email}</p>
                  </div>
                ) : (
                  <div className="animate-pulse space-y-2">
                    <div className="h-2 w-32 bg-slate-400 rounded "></div>
                    <div className="h-2 bg-slate-400 rounded "></div>
                  </div>
                ))}
            </div>
            {sidebarClosingStatus && (
              <svg
                onClick={handleLogout}
                width="18"
                height="18"
                className="mt-2 cursor-pointer"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
