import React, { useEffect } from "react";
import axios from "axios";
import logo from "../../assets/images/logo.png";
import { toast } from "sonner";
import { useSelector } from "react-redux";

const PaymentTables = () => {
  const user = useSelector((state) => state.auth.user);

  //   const newUser = useSelector((state) => state.auth.newUser);
  useEffect(() => {
    // fetch payment options
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      toast.error("Create an Account first!");
      window.location.href = "/signup";
    }

    // const userDetails = axios.get(process.env.REACT_APP_BASE_URL + "/user/details", {
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //     },
    // });

    // if(userDetails.data?.subscritptionActive){
    //     setActivePriceId(userDetails.data.planId)
    // }
  }, []);

  return (
    <>
      <div
        className={`px-5 py-3.5 flex  w-full justify-between items-center font-Inter`}
      >
        <div className="flex items-center space-x-3 w-[60%] max-w-[240px]">
          <img src={logo} className="w-3/4 h-auto" />
        </div>
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <div className="grid min-h-screen place-items-center">
        <div className="items-center justify-center w-full">
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PK}
            customer-email={user?.email}
            customer-name={user?.name}
          ></stripe-pricing-table>
        </div>
      </div>
    </>
  );
};

export default PaymentTables;
