import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const handlePayment = (priceId) => {
  return async (dispatch) => {
    const response = await authenticatedInstance.post(
      process.env.REACT_APP_BASE_URL + "/stripe/create-checkout-session",
      {
        // lookup_key: event.target.lookup_key.value,
        priceId: priceId,
      }
    );
    const url = response.data;
    window.location.href = url;
  };
};

export const getCredits = () => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get(
      "/stripe/getcredits"
    );
    if (status === 200) {
      dispatch({
        type: "CHANGE_USER_CREDITS",
        payload: data,
      });
    }
  } catch (error) {
    console.log(error, "Error in get settings");
    toast.error("Couldn't update credits");
  }
};

export const handleAddCredits = async (priceId) => {
  try {
    const response = await authenticatedInstance.post(
      process.env.REACT_APP_BASE_URL + "/stripe/one-time-checkout-session",
      {
        priceId: priceId,
      }
    );

    const url = response.data;
    window.location.href = url;
  } catch (error) {
    console.error("Error in handleAddCredits:", error.message);
  }
};

export const handlePortalSession = async () => {
  const response = await authenticatedInstance.post(
    "/stripe/create-customer-portal-session",
    {
      // lookup_key: event.target.lookup_key.value,
    }
  );
  const url = response.data;
  window.location.href = url;
};

export const handleEndFreeTrial = async () => {
  try {
    const response = await authenticatedInstance.get("/stripe/end-free-trial");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
