import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCredits,
  handleAddCredits,
  handlePortalSession,
} from "../redux/actions/payment";
import { toast } from "sonner";

export const Dashboard = (props) => {
  const { userCredits } = useSelector((state) => state.stripe);
  const dispatch = useDispatch();
  const buttonData = [
    {
      credits: 200,
      amount: 10,
      priceId: process.env.REACT_APP_ADD_200_CREDITS,
    },
    {
      credits: 500,
      amount: 25,
      priceId: process.env.REACT_APP_ADD_500_CREDITS,
    },
    {
      credits: 750,
      amount: 50,
      priceId: process.env.REACT_APP_ADD_750_CREDITS,
    },
  ];
  const handleAddButtonClick = async (priceId) => {
    handleAddCredits(priceId);
  };
  useEffect(() => {
    dispatch(getCredits());
    const queryParameters = new URLSearchParams(window.location.search);
    const payment_status = queryParameters.get("payment_status");
    const session_id = queryParameters.get("session_id");
    if (payment_status && session_id) {
      if (payment_status === "success") {
        toast.success("Payment successful");
      } else if (payment_status === "failed") {
        toast.error("Payment failed!");
      }
      if (session_id) {
        queryParameters.delete("session_id");
      }
      queryParameters.delete("payment_status");
      // remove query parameters from url
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);
  return (
    <>
      <p className="title border-b border-b-gray-300 w-full p-5">Dashboard</p>
      <div className="px-8 py-4">
        <div className="flex items-center gap-x-5 pt-4 w-full">
          <div className="flex flex-col gap-y-2 p-6 rounded-lg border border-gray-300 shadow w-1/4">
            <p>Your Balance</p>
            <p className="text-2xl font-medium">
              {userCredits?.credit_balance}
            </p>
          </div>
          <div className="flex flex-col gap-y-2 p-6 rounded-lg border border-gray-300 shadow w-1/4">
            <p>Total Credits Purchased</p>
            <p className="text-2xl font-medium">{userCredits?.credit_total}</p>
          </div>
          <div className="flex flex-col gap-y-1 p-4 rounded-lg border border-gray-300 shadow w-1/4">
            <p>Your Plan</p>
            <p className="text-2xl font-medium">
              {userCredits?.plan ? userCredits?.plan : "-"}
            </p>
            <p className="text-gray-600 ml-auto text-sm">
              Ending at{" "}
              {new Date(userCredits?.subscriptionEnd).toLocaleDateString(
                "en-GB"
              )}
            </p>
          </div>
        </div>
        <div className="flex gap-x-3 items-center py-5">
          {buttonData?.map(({ credits, amount, priceId }, i) => {
            return (
              <button
                className="px-6 py-3 rounded-lg border border-gray-300 text-primary shadow font-medium hover:border-green-500 transition-all duration-150 ease-in-out"
                key={i}
                onClick={() => handleAddButtonClick(priceId)}
              >
                Buy {credits} credits for ${amount}
              </button>
            );
          })}
        </div>
        <div className="flex items-center gap-x-4">
          <button
            className="px-6 py-3 rounded-lg border border-gray-300 text-gray-700 shadow font-medium hover:border-gray-400 transition-all duration-150 ease-in-out"
            onClick={handlePortalSession}
          >
            Change your plan
          </button>
          <div className="flex items-center gap-x-3 px-6 py-2 border-gray-300 text-gray-700 shadow transition-all duration-150 ease-in-out w-fit rounded-lg border font-medium">
            <p> Subscription status: </p>
            <p
              className={`${
                userCredits?.subscriptionActive
                  ? "bg-green-50 text-green-500"
                  : "bg-red-50 text-red-500"
              } rounded-lg px-3 py-1`}
            >
              {userCredits?.subscriptionActive ? "Active" : "Inactive"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
